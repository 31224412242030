import React from 'react';
import Layout from "../components/styling/layout"
import styled from 'styled-components'

const StyledNotFoundContainer = styled.div`
  text-align: center;
  padding-top: 100px;
`

const NotFoundPage = () => (
  <Layout>
    <StyledNotFoundContainer>
      <h2>404 not found... :(</h2>
    </StyledNotFoundContainer>
  </Layout>
)

export default NotFoundPage;
